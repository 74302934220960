.news-date-header {
    font-family: "PantonBlackCapsItalic", Arial, Helvetica, sans-serif;
    background-color: gray;
    padding: 3px 5px;
    color: white;
    margin-top: 18px;
    width: 40%;
}

.news-item {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.news-item-data {
    display: flex;
    flex-direction: column;
    margin-left: 2px;
}

.news-site-logo {
    width: 24px;
    height: 24px;
}

.news-title {
    font-size: 13px;
}

.news-title a {
    color: black;
}

.news-item-second-line {
    line-height: 0.8em;
    color: darkgray;
}