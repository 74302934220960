.faq.list-table {
    font-family: "Libre Franklin"
}

.faq-list.question.box {
    line-height: 0.9rem;
    margin-bottom: 3px;
}

.faq.list-table.question.text {
    /* color: #555; */
}

.header-explainer {
    color: gray;
    margin-top: -10px;
    max-width: 600px;
    line-height: 1rem;
}

.faq-list.box {
    margin-top: 10px;
}

.faq-single.box {
    margin: 20px;
    display: flex;
    flex-direction: column;
    max-width: 600px;
}

.faq-single.title.text {
    line-height: 2.7rem
}

.twitter-tweet {
    border: 1px solid gray;
    padding: 5px;
    border-radius: 5px;
}