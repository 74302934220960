@font-face {
    font-family: 'PantonBlackCaps';
    font-weight: bold;
    src: url("../webfonts/Panton-BlackCaps.otf") format("opentype");
}

@font-face {
    font-family: 'PantonBlackCapsItalic';
    font-weight: bold;
    src: url("../webfonts/Panton-BlackitalicCaps.otf") format("opentype");
}

@font-face {
    font-family: 'PantonLightCaps';
    font-weight: bold;
    src: url("../webfonts/Panton-LightCaps.otf") format("opentype");
}

@font-face {
    font-family: 'PantonLightCapsItalic';
    src: url("../webfonts/Panton-LightItalicCaps.otf") format("opentype");
}

@font-face {
    font-family: 'Exo2';
    src: url("../webfonts/Exo2-Light.ttf") format("truetype");
}

@font-face {
    font-family: 'Libre Franklin Italic';
    src: url("../webfonts/LibreFranklin-Italic-VariableFont_wght.ttf") format("truetype");
}

@font-face {
    font-family: 'Libre Franklin';
    src: url("../webfonts/LibreFranklin-VariableFont_wght.ttf") format("truetype");
    font-weight: 125 950;
    font-stretch: 75% 125%;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url("../webfonts/Inter.ttf") format("truetype");
    font-style: normal;
}

.superindy-container {
    padding: 10px;
}

.superindy-header {
    padding-left: 10px;
}

.libre {
    font-family: "Libre Franklin"
}

.container-fluid {
    padding: 0 !important;
}

.driver {
    cursor: pointer;
}

.pickem-group-selector-toggle {
    padding: 5px;
    cursor: pointer;
}

.pickem-group-selector-active {
    background-color: gray;
}

.propbet-sidebar-mobile-item {
    height: 38px;
    width: 38px;
    background-color: white;
    border: 1px black solid;
    align-items: center;
    justify-content: center;
    margin-bottom: -1px;
    margin-left: -1px;
    padding: 5px;
    display: flex;
}

.propbet-sidebar-desktop-item {
    width: 100%;
    background-color: white;
    border: 1px black solid;
    align-items: center;
    padding: 5px;
    display: flex;
    cursor: pointer;
    line-height: 1.2em;
    margin-bottom: -1px;
    font-family: "Exo2";
}

.propbet-question-number {}

.propbet-subtext {
    text-align: center;
    font-style: italic;
    margin-top: -12px;
}

.propbet-option {
    width: 51%;
    padding: 18px;
    border: 1px solid gray;
    margin-bottom: 10px;
    cursor: pointer;
}

.propbet-option-2up {
    width: 35%;
    padding: 18px;
    border: 1px solid gray;
    margin: 0 0 10px 10px;
    cursor: pointer;
}

.driver-grid-box-3-up-container {
    display: flex;
    flex-wrap: wrap;
}

.driver-grid-box-3-up {
    font-size: 0.8em;
    width: calc(100% * (1/3) - 10px - 1px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.propbet-option-3up {
    flex: 1 0 29%;
    /* explanation below */
}

.propbet-selected,
.propbet-option-selected {
    background-color: #4CC552;
}

.propbet-viewing {
    border: 4px solid #E56717;
    padding: 2px;
}

#pick-options-container {
    display: flex;
    flex: 1;
}

.layout-row {
    flex-direction: row;
}

.layout-column {
    flex-direction: column;
}

.all-picks-made {
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.flag-large {
    font-size: 4em;
    margin-bottom: 15px;
}

.finished-entry-id-label {
    font-size: 1.3em;
    margin-top: 20px;
    line-height: .5em;
}

.finished-entry-id-text {
    font-size: 1.5em;
    font-weight: bold;
}

.driver-grid-box-2-up {
    font-size: 0.8em;
    width: calc(100% * (1/2) - 10px - 1px);
}

.propbet-option-disabled {
    background-color: crimson;
    pointer-events: none;
    opacity: 0.4;
    color: darkgray
}

.timezone-container {
    display: flex;
    flex-wrap: wrap;
}

.timezone {
    margin: 0 15px 15px 0;
    width: 80px;
}

.timezone-abbr {
    line-height: 0.8rem;
}

.timezone-name {
    color: gray;
    font-size: 0.8rem;
    line-height: 0.8rem;
    margin-top: 2px;
}