.book-container {
    flex-wrap: wrap;
    flex-direction: row;
    display: flex;
    width: 1000px;
    height: 200px
}

.book-tile {

    width: 100px;
    color: red
}