#race-weekend {
    margin: 20px;
}

#race-weekend-header {
    display: flex;
    font-family: "Libre Franklin";
    font-weight: 600
}

.race-weekend.race-info-box {
    display: flex;
    flex-direction: column;
}

.race-weekend.race-name {
    margin-bottom: -5px;
    margin-left: -2px;
}

.race-weekend.race-name-official {}

.race-weekend.race-logo {
    max-height: 80px;
}

.race-weekend.race-items {
    display: flex;
    flex-direction: column;
}

.race-weekend-icon {
    color: gray;
    width: 15px;
    margin-right: 5px;
}

.race-weekend.sessions {
    font-family: "Exo2"
}

.race-weekend.session.name {
    width: 90px;
}

.race-weekend.session.date {
    width: 90px;
}

.race-weekend.session.times {
    width: 120px;
}

.race-weekend.session.session-asset-icon {
    color: gray;
    text-align: center;
    width: 30px;
}

.race-weekend.session.tweets {

}