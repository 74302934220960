.radio-freqs.radio-container {
    width: 500px;
}

.radio-freqs.download-container {
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
}

.radio-freqs.download-explainer {
    color: gray;
    margin-top: -15px;
    margin-bottom: 7px;
}