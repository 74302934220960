.item {
  display: flex;
}

.number {
  font-size: 1.5rem;
  font-weight: 600;
  width: 70px;
}

.title {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -1px;
  margin-top: -5px;
  line-height: 2rem;
}

.itemBox {
  border: 1px solid #eaeaea;
  background-color: #f3f3f3;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 8px 10px;
}

.introText {
  line-height: 1rem;
  font-size: 1.1rem;
}

.actionBar {
}

.button {
  width: fit-content;
}

.filesContainer {
  display: flex;
  flex-wrap: wrap;
}

.file {
  text-align: center;
  margin: 4px 4px 0 0;
  line-height: 1.1;
}

.file-link {
  color: transparent;
}

.fileName {
  color: royalblue;
  text-decoration: none;
  max-width: 80px;
}

.fileName:hover {
  color: royalblue;
  text-decoration: none;
}

.tag {
  margin-right: 3px;
  border: 1px solid #343a40;
  cursor: pointer;
  font-size: 8pt;
}
