@font-face {
    font-family: 'Alegreya';
    font-weight: bold;
    src: url("../webfonts/Alegreya-Regular.otf") format("opentype");
}

.scoreboard {
    display: flex;
    flex-direction: row;
}

.scoreboard-header {
    margin-left: 15px;
}

.score-cell {
    flex: 0
}

.answer-score-cell {
    height: 20px;
    width: 20px;
}

.answer-right {
    color: green;
}

.answer-wrong {
    color: maroon;
}

.scoreboard-icon-pick-result {
    text-align: center;
    cursor: pointer;
}

.highlight-score-row {
    background-color: #f1f1f1;
}

.highlight-score-row:nth-child(even) {
    background-color: white;
}

.board-season-row {
    background-color: #f1f1f1;
}

.board-season-row:nth-child(even) {
    background-color: white;
}

.highlight-winner-row {
    background-color: lightgreen !important;
    border-top: 1px solid green !important;
    border-bottom: 1px solid green !important;
}

.table-scores {
    margin-bottom: 50px !important;
}

.table-scores td,
.table-scores th {
    /* padding: 0.85rem !important; */
    font-size: 0.8em;
}

/* 
.table > thead:first-child > tr:first-child > th:first-child,
.table > thead:nth-child(2) > tr:nth-child(2) > th:nth-child(2) {
    position: absolute;
    display: inline-block;
    background-color: lightblue;
    height: 100%;
}

.table > tbody > tr > td:first-child,
.table > tbody > tr > td:nth-child(2) {
    position: absolute;
    display: inline-block;
    background-color: lightblue;
}

.table > thead:first-child > tr:first-child > th:nth-child(2),
.table > thead:nth-child(2) > tr:nth-child(2) > th:nth-child(3) {
    padding-left: 40px;
}

.table > tbody > tr > td:nth-child(2),
.table > tbody > tr > td:nth-child(3) {
    padding-left: 50px !important;
} */

.scoreboard-match-name {
    font-family: "PantonBlackCaps", Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    line-height: 1.3rem;
}

.scoreboard-message {
    font-family: "Alegreya", Arial, Helvetica, sans-serif
}

.scoreboard-container {}

.scoreboard-th-score {
    text-align: center;
}

.scoreboard-score {
    text-align: center;
}

.scoreboard-td {
    width: 30px;
}

.scoreboard-team-logo {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.scoreboard-team-logo-underway-not-scored {
    background-color: rgb(211, 227, 227);
}

.scoreboard-team-logo-future-not-scored {
    background: repeating-linear-gradient(-45deg, white, white 10px, #f1f1f1 10px, #f1f1f1 20px);
}

.scoreboard-team-logo-question-canceled {
    filter: opacity(70%) grayscale(100%) drop-shadow(5px 5px 2px red) !important;
}

.scoreboard-team-logo-scored-wrong {
    /* filter: opacity(50%) grayscale(100%); */
}

.scoreboard-team-logo-container {
    display: flex;
    justify-content: center;
}

.scoreboard-team-logo-scored-wrong-x {
    display: inline-block;
    width: 10px;
    height: 10px;
    /* border: 7px solid #f56b00; */
    background: linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 43%, var(--scored-wrong-x-color) 45%, var(--scored-wrong-x-color) 55%, rgba(0, 0, 0, 0) 57%, rgba(0, 0, 0, 0) 100%), linear-gradient(135deg, transparent 0%, transparent 43%, var(--scored-wrong-x-color) 45%, var(--scored-wrong-x-color) 55%, transparent 57%, transparent 100%);
}

.pick-circle-wrong {
    color: red;
    padding-top: 5px;
}

.scoreboard-team-logo-scored-right {}

.scoreboard-team-logo-over-under {
    width: 15px
}

.scoreboard-box-stats-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 7px;
}

.last-scored-text {
    font-family: "Exo2", Arial, Helvetica, sans-serif;
}

.scoreboard-instructions-text {
    font-family: "Exo2", Arial, Helvetica, sans-serif;
    line-height: 1rem;
}

.scoreboard-stat-box {
    font-size: 0.8em;
    /* change icon size */
    display: block;
    position: relative;
    min-width: 5.5em;
    /* height: 7em; */
    background-color: #fff;
    border-radius: 0.6em;
    box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 0 0 1px #bdbdbd;
    /* Triple page on bottom to look like calendar */
    /* box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff; */
    overflow: hidden;
    margin-right: 5px;
}

.scoreboard-stat-box * {
    display: block;
    width: 100%;
    font-size: 1em;
    font-weight: bold;
    font-style: normal;
    text-align: center;
}

.scoreboard-stat-box strong {
    position: absolute;
    top: 0;
    /* padding: 0.4em 0; */
    color: #fff;
    background-color: gray;
    border-bottom: 1px dashed darkgray;
    box-shadow: 0 2px 0 gray;
}

.scoreboard-stat-box-canceled strong {
    color: #fff;
    background-color: red;
    border-bottom: 1px dashed darkred;
    box-shadow: 0 2px 0 red;
}

.scoreboard-stat-box em {
    position: absolute;
    bottom: 0.3em;
    color: #dc3545;
}

.scoreboard-stat-box span {
    font-size: 2.8em;
    letter-spacing: -0.05em;
    padding-top: 0.5em;
    color: #2f2f2f;
    line-height: 2.6rem;
    margin-top: 2px;
    color: #2f2f2f;
}

.over-under-team-logos {
    display: flex;
    flex-direction: row;
    align-self: center;
}

.over-under-pick {
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
}

.pick-right {
    color: green;
}

.pick-wrong {
    color: red;
}

.pick-not-scored {
    color: lightblue;
}

.question-canceled {
    color: gray;
}

.over-under-right {
    color: green;
}

.over-under-wrong {
    color: #aaa;
}

.over-under-not-scored {
    color: gray;
}

.player-picks-team-logo {
    height: 20px;
}

.picks-modal-row {
    cursor: pointer;
}

.player-picks-question-canceled {
    text-decoration: line-through;
    text-decoration-thickness: 2px;
}

.qa-header {
    font-family: "PantonBlackCaps", Arial, Helvetica, sans-serif;
    line-height: 1rem;
}

.qa-text {
    margin-bottom: 10px;
    line-height: 1.1rem;
}

.scoreboard-button {
    padding: 20px;
    border: 1px solid black;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: lightblue;
    color: black;
    width: 230px;
    display: block;
}

.scoreboard-button-scored-text {
    font-family: Arial;
    line-height: 0.5em;
    color: gray;
    font-style: italic;
    font-size: 0.9em;
    text-align: center;
}

.scoreboard-button:hover {
    color: black;
    text-decoration: none;
}

.scoreboard-scored-total {
    margin-left: 15px;
}

.scoreboard-name {
    cursor: pointer;
    line-height: 1rem;
}

.scoreboard-place,
.scoreboard-name,
.scoreboard-nickname,
.scoreboard-score,
.board-season-rank,
.board-season-name,
.board-season-score,
.board-season-game-count,
.board-season-correct-percent {
    font-family: "Exo2", Arial, Helvetica, sans-serif;
}

.scoreboard-place,
.scoreboard-name,
.scoreboard-score,
.board-season-rank,
.board-season-name,
.board-season-score,
.board-season-game-count,
.board-season-correct-percent  {
    font-size: 1.1rem !important;

}

.board-season-score,
.board-season-game-count {
    /* text-align: center; */
}

.scoreboard-nickname {
    font-size: 0.8rem;
    line-height: 0.8rem;
}

.scoreboard-name:hover {
    color: #07c;
}

.scoreboard-picks-modal {
    font-size: 0.8rem;
}

.scoreboard-place-th {
    width: 50px;
}

.scoreboard-name-th {
    width: 150px;
}

.scoreboard-score-th {
    width: 50px;
}

.picks-modal-body {
    padding: 0.5rem !important;
}

.picks-modal-game-name {
    font-size: 0.9rem;
    color: gray;
}

.picks-modal-nickname {
    font-size: 1rem;
    color: gray;
}

.picks-modal-intro-text {
    line-height: 0.9rem;
}

.scoreboard-types-text {
    font-weight: 700;
}

.scoreboard-button-types {
    display: flex;
    align-items: center;
}

.btn-rounded {
    border-radius: 4px !important;
}

.scoreboard-team-name {
    width: 100px;
    display: block;
    line-height: 0.7rem;
    margin-top: -2px;
}

.name-td {
    padding-top: 0.15rem !important;
    padding-bottom: 0.15rem !important;
}

.scoreboard-mode-active {
    background-color: var(--indyspeed-red) !important;
    border-color: #dc3545 !important;
}

.scoreboard-current-game {
    background-color: rgba(232, 107, 29, 0.8)
}

.th-pick-header {
    text-align: center;
}

.th-pick-header-game-scored {
    color: green;
}

.th-pick-header-question-canceled {
    color: gray;
}

.cell-modal-broadcast-title {
    font-weight: 600;
    font-family: "PantonBlackCaps", Arial, Helvetica, sans-serif;
}

.current-user-scoreboard-row {
    background-color: lightsalmon !important;
}

/*************************************************************************************************** SEASON SCOREBOARD */
.table-board-season {
    width: max-content !important;
}

.board-season-th {
    text-align: left !important;
    font-size: 1rem !important;
}

.board-season-rank-th {
    width: 50px;
}

.board-season-name-th {
    width: 100px;
}

.board-season-score-th {
    width: 70px !important;
}

.board-season-games-th {
    width: 50px;
}

.board-season-row > td {
    /* padding: 0 !important; */
}

.board-season-th-row > th {
    padding: 0.1rem 0.6rem 0.1rem 0.1rem !important;
    margin-right: 10px;
    text-align: left !important;
}

.board-season-row > td {
    padding: 0.1rem 0.2rem 0.1rem 0.2rem !important;
}