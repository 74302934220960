.h1 {
  font-family: Inter, Arial, Helvetica, sans-serif;
  letter-spacing: -4px;
  font-size: 80px;
  font-weight: 700;
  line-height: 0.8;
}

.title {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 50px;
  font-weight: 600;
  line-height: 0.8;
}

.portfolioContainer {
}
