.header-super {
    font-family: "PantonBlackCaps", Helvetica, Arial, sans-serif;
    color: #dc3545;
}

.header-indy {
    font-family: "PantonBlackCaps", Helvetica, Arial, sans-serif;
    color: gray
}

.header-version {
    font-size: 0.5em;
    line-height: 0em;
    font-style: italic;
}

.header-made-by-merrick-speed {
    font-family: "PantonBlackCapsItalic";
    font-size: 0.6em;
    color: lightgray;
    line-height: 0em;
}

.header-menu-item {
    font-family: "PantonBlackCaps", Helvetica, Arial, sans-serif;
    font-size: 1em;
    color: darkgray;
}

.nav-link {
    margin-right: 20px;
}

.container.standard {
    margin: 20px 0 0 20px;
}