/*********************************************************************************************************************************** NEW BUTTON STYLES */

.si-btn {
  padding: 14px 24px;
  border: 0 none;
  font-weight: 400;
  /* letter-spacing: 1px; */
  text-transform: uppercase;
  border-radius: 0 !important;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: 0 none;
}

.btn-primary {
  background: #0099cc !important;
  border: 0 !important;
  color: #ffffff;
}

.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary {
  background: #33a6cc !important;
}

.btn-primary:active,
.btn-primary.active {
  background: #007299 !important;
  box-shadow: none;
}

/*********************************************************************************************************************************** VARIABLES */

:root {
  --main-white-color: black;
  --main-purple-color: #9e89b8;
}

/*********************************************************************************************************************************** STYLES */

.landing-text {
  text-align: left;
  color: gray;
  border-left: 4px darkgray solid;
  padding-left: 3px;
  margin-bottom: 4px;
  font-family: Helvetica, sans-serif
}

.page-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  min-height: 350px;
  /* background: var(--main-purple-color) url("") no-repeat center / cover; */
  background-blend-mode: luminosity;
  color: var(--main-white-color);
}

.page-main div {
  max-width: 600px;
}

.landing-form {
  padding: 10px;
  background-color: lightgray;
  margin-top: 20px;
}

.landing-text-container {
  display: flex;
  flex-direction: column;
}

.entry-container {
  margin-right: 15px;
}

.landing-subsection-text {
  margin-top: 10px;
}

#entry-options {
  margin-top: 10px;
  width: 200px;
}

#already-entry {
  margin-top: 10px;
  font-size: 0.9em;
}

.game-landing-header-text {
  font-size: 2em;
  color: #dc3545;
}

#update-entry-link {
  font-size: 0.8em;
  color: blue;
  cursor: pointer;
  font-style: italic;
}

#update-entry-link:hover {
  color: gray;
}

#start-form {
  display: flex;
  flex-direction: column;
}

#form-name,
#team-name,
#form-twitter,
#form-entry-id {
  font-family: "Helvetica"
}

.landing-form-button {
  display: flex;
  align-self: center;
}

.form-text {
  margin-top: 0 !important;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.invalid-form-input {
  color: red;
}

@media screen and (min-width: 768px) {
  .registration-and-rules-container {
    display: flex;
  }

  #scores-ready-text {
    max-width: 250px;
    line-height: 1.1rem;
  }

  #scores-ready-text p {
    margin-top: 10px;
  }

  .page-main div {
    max-width: 800px;
  }
}

@media screen and (max-width: 767px) {
  .registration-and-rules-container {
    display: flex;
    flex-direction: column;
  }

  .entry-container {
    border-bottom: 3px dashed black;
    padding-bottom: 7px;
  }
}

.page-main {
  padding-left: 10px;
}

.start-new-button {
  border: 3px pink solid;
  height: 60px;
  width: 200px;
}