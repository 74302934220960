:root {
    --scored-wrong-x-color: gray;
    --indyspeed-red: #dc3545;
}

.game-title {
    font-family: "PantonBlackCapsItalic", Arial;
    color: #342251;
    line-height: 2rem;
    margin-top: 10px;
}

.td-image-pick {
    text-align: center;
}

.all-game-info-container {
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.game-container {
    display: flex;
    justify-content: center;
    font-size: 1rem;
    border-bottom: 1px solid #333;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.team-container {
    max-width: 100px;
}

.sport-container {
    display: flex;
}

.sport-logo-image {
    height: 40px;
    align-self: center;
}

.away-team-container {
    text-align: right;
    display: flex;
}

.team-text-info-container {
    display: flex;
    flex-direction: column;
}

.home-team-container {
    text-align: left;
    display: flex;
}

.mobile-prop-box-container {
    padding: 0 !important;
    margin-left: 10px;
    margin-right: 10px;
}

#mobile-sidebar-number-completed {
    text-align: center;
    margin: 0 0 15px 0;
    background-color: lightgray;
}

.team-place {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: "PantonLightCaps", Helvetica, Arial, sans-serif;
    line-height: 1rem;
}

.team-rank {}

.team-logo {
    width: 70px;
    margin-top: -5px;
}

.away-team-logo {
    margin-left: 10px;
}

.home-team-logo {
    margin-right: 10px;
}

.team-nickname {
    font-family: "PantonBlackCaps", Helvetica, Arial, sans-serif;
    line-height: 1.2rem;
    font-size: 1.5rem;
}

.team-record {
    font-family: "PantonLightCaps", Helvetica, Arial, sans-serif;
    line-height: 0.9rem;
}

.game-versus-text {
    margin: 15px 20px 0 20px;
    font-family: "PantonLightCaps", Helvetica, Arial, sans-serif;
}

.propbet-text {
    text-align: center;
    display: flex;
    flex: 0 0 auto;
    font-family: "PantonBlackCaps";
    text-transform: uppercase;
    align-self: center;
    line-height: 1.5rem;
    margin: 10px 0 10px 0;
}

.propbet-points {
    text-align: center;
    font-style: italic;
    margin-bottom: 5px;
    font-family: "Exo2";
}

.propbet-option-text {
    line-height: 1.1rem;
    font-size: 1rem;
    font-family: "Exo2", Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: center;
    align-content: center;
}

.propbet-option-subtext {
    font-size: 0.8em;
    font-style: italic;
}

@media screen and (max-width: 500px) {
    /* .table-scores tr th, .table-scores tr td {
        display: none
    }
    .table-scores tr td:nth-child(0), .table-scores tr td:nth-child(1), .table-scores tr td:nth-child(2), .table-scores tr td:nth-child(3), .table-scores tr th:nth-child(0), .table-scores tr th:nth-child(1), .table-scores tr th:nth-child(2), .table-scores tr th:nth-child(3) {
        display: table-cell
    } */
}

@media screen and (max-width: 768px) {
    .game-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 30px;
        font-size: 1rem;
    }

    .game-title {
        width: 330px;
    }

    .sport-logo-image {
        position: absolute;
        margin-top: 10px;
    }

    .away-team-container {
        justify-content: center;
        margin-top: -15px;
    }

    .home-team-container {
        justify-content: center;
    }

    .game-versus-text {
        text-align: center;
        margin: -2px 0 -4px 0px;
        color: #ff5511;
        font-family: "PantonLightCaps", Helvetica, Arial, sans-serif;
    }

    .propbet-option {
        width: 80% !important;
        padding: 18px;
        border: 1px solid gray;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .picks-made-progress {
        margin-top: 5px;
        margin-left: -1px;
    }

    #pick-options-container {
        margin-top: -20px;
    }

    .propbet-text {
        margin: -15px 0 10px 0;
    }

    .propbet-points {
        text-align: center;
        font-style: italic;
        margin-bottom: 20px;
    }

    .picks-made-text {
        margin-top: 0 !important;
        text-align: center;
        font-size: 0.8rem;
        font-weight: bold;
    }

    .pickem-progress {
        margin-bottom: 15px !important;
        margin-top: -15px;
    }

    .team-logo {
        width: 50px;
    }

    .team-record {
        line-height: 0.8rem;
    }
}

@media screen and (min-width: 769px) {
    #prop-bet-question-container {
        height: 100%;
        border: 1px solid black;
        border-radius: 4px;
        padding: 10px;
        margin-left: 10px;
    }
}

.pick-options-flex {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
}

.left-pickem-footer, .center-pickem-footer, .right-pickem-footer {
    display: flex;
    flex: 1;
}

.left-pickem-footer {
    justify-content: flex-start;
}

.center-pickem-footer {
    justify-content: space-around;
    align-items: center;
}

.right-pickem-footer {
    justify-content: flex-end;
}

.desktop-sidebar-container {
    background-color: lightgray;
    padding-bottom: 10px;
    margin-left: 10px;
}

#desktop-sidebar-number-completed {
    margin-bottom: 5px;
}

#prop-bet-question-container {
    display: flex;
    flex-direction: column;
}

.pickem-progress {
    margin-bottom: 5px;
    border-radius: 0 !important;
}

.picks-made-text {
    margin-top: 3px;
    margin-bottom: -5px;
    text-align: center;
    font-size: 0.8rem;
    font-weight: bold;
}

.highlight-score-row {
    height: 60px;
    vertical-align: middle;
}

.table-scores td {
    vertical-align: middle !important;
}

/******************************************************************************************* HORIZONTAL SCROLL BAR */

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.scrollbar-container {
    margin: 5px;
}

.scrollbar-arrow {
    margin: 10px;
}

.scrollbar-card {
    text-align: center;
    width: 120px;
    cursor: pointer;
}

.scrollbar-card:hover {
    font-weight: bold;
}

.scrollbar-card-title {
    font-size: 12px;
    line-height: 0.8rem;
}

.scrollbar-card-subtitle {
    font-size: 10px;
}

.finished-picks {
    margin-top: 8px;
    font-family: "Exo2";
    line-height: 1.2rem;
}